<template>
  <div id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <user-bio-panel v-if="resellerData" :user="resellerData"></user-bio-panel>
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-card-title class="pl-0">
          <v-icon class="return-button" @click="$router.back()">{{ icons.mdiKeyboardBackspace }}</v-icon>
          <span class="ml-3">
            Reseller <span class="font-weight-bold"> {{ resellerData.firstName }}&nbsp;{{ resellerData.lastName }}</span>
          </span>
        </v-card-title>
        <v-tabs v-model="tab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.id" :id="tab.id">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="admin-tabs-content" v-model="tab" class="mt-5 pa-1">
          <v-tab-item>
            <user-security></user-security>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import router from '@/router';
import store from '@/store';
import { mdiKeyboardBackspace, mdiLockOutline } from '@mdi/js';
import UserSecurity from '@/views/shared/users/_partials/UserSecurity.vue';
import UserBioPanel from '@/views/shared/users/UserBioPanel.vue';

export default {
  components: {
    UserBioPanel,
    UserSecurity,
  },
  setup() {
    const resellerData = ref(null);
    const tab = ref(null);

    store
      .dispatch('user/fetchById', router.currentRoute.params.id)
      .then(response => {
        resellerData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          resellerData.value = {};
        }
      });

    const tabs = [{ icon: mdiLockOutline, title: 'Security', id: 'admin-security-tab' }];

    return {
      tabs,
      tab,
      resellerData,
      icons: {
        mdiKeyboardBackspace,
      },
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user';
</style>
